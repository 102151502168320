import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cffc55d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "time-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 1,
  class: "errors"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "time",
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', $event.target.value)))
    }, null, 40, _hoisted_3),
    (_ctx.errors.length !== 0)
      ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, index) => {
            return (_openBlock(), _createElementBlock("span", { key: index }, _toDisplayString(error.$message), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}