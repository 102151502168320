
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "BaseTimeInput",
  props: {
    modelValue: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    errors: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },
  },
});
